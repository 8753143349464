import React, { Component } from 'react';
import _ from 'lodash';

import DigitTable from './DigitTable';

export default class FullTable extends Component {
  state = {
    startNumber: 1,
    endNumber: 10,
    totalTable: 10,
    digits: _.range(1, 11),
  };

  handleStartChange(number) {
    const startNumber = Number(number) || 1;
    const { totalTable } = this.state;

    this.setState({
      startNumber: startNumber,
      endNumber: totalTable + startNumber - 1,
      digits: _.range(startNumber, startNumber + totalTable),
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="flex items-center gap-4 pb-5">
          <h1 className="text-lg md:text-3xl whitespace-nowrap">Multiplication Tables</h1>
          <div className="flex space-x-2">
            <input className="w-14 px-3 text-base text-gray-700 border rounded" type="number" step="1" value={this.state.startNumber} onChange={e => this.handleStartChange(e.target.value)} />
            <span>to</span>
            <input className="w-14 px-3 text-base text-gray-700 border rounded" type="number" step="1" value={this.state.endNumber} disabled={true} />
          </div>
        </div>
        <div className="flex flex-wrap content-evenly gap-4 md:text-2xl">
          {this.state.digits.map(digit => (
            <DigitTable key={digit} digit={digit} />
          ))}
        </div>
      </React.Fragment>
    );
  }
}
