import React, { Component } from 'react';

export default class DigitTable extends Component {
  state = {
    multipliers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  };

  render() {
    const { digit } = this.props;
    return (
      <table>
        <tbody>
          {this.state.multipliers.map(multiplier => {
            return (
              <tr key={`${digit}x${multiplier}`}>
                <td className="border border-gray-300 px-5 md:px-8">{`${digit} x ${multiplier} = ${digit * multiplier}`}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}
