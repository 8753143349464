import React from 'react';

export default function TitleReload(props) {
  return (
    <div className="flex items-center space-x-5">
      <h1 className="text-lg md:text-3xl whitespace-nowrap">{props.title}</h1>
      <img src="./icons/sync-alt-solid.svg" alt="Reload" className="w-5 h-5 cursor-pointer" onClick={props.handleReload} />
    </div>
  );
}
