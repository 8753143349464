import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Navbar from './common/Navbar';
import Home from './common/Home';
import FullTable from './basic/FullTable';

import BasicAddition1 from './basic/add/BasicAddition1';
import BasicAddition2 from './basic/add/BasicAddition2';
import BasicAddition3 from './basic/add/BasicAddition3';

import MediumAddition1 from './medium/add/MediumAddition1';
import MediumAddition2 from './medium/add/MediumAddition2';
import MediumAddition3 from './medium/add/MediumAddition3';

import BasicSubtraction1 from './basic/sub/BasicSubtraction1';
import BasicSubtraction2 from './basic/sub/BasicSubtraction2';
import BasicSubtraction3 from './basic/sub/BasicSubtraction3';

import MediumSubtraction1 from './medium/sub/MediumSubtraction1';
import MediumSubtraction2 from './medium/sub/MediumSubtraction2';

import BasicMultiplication1 from './basic/mul/BasicMultiplication1';
import BasicMultiplication2 from './basic/mul/BasicMultiplication2';
import BasicMultiplication3 from './basic/mul/BasicMultiplication3';

import BasicDivision1 from './basic/div/BasicDivision1';
import BasicDivision2 from './basic/div/BasicDivision2';
import BasicDivision3 from './basic/div/BasicDivision3';

export default class App extends Component {
  render() {
    return (
      <div className="w-full">
        <Navbar />
        <div className="container md:mx-auto px-3 md:px-0 my-5 text-lg text-gray-800">
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/table" component={FullTable} />

            <Route path="/basic-addition-1" component={BasicAddition1} />
            <Route path="/basic-addition-2" component={BasicAddition2} />
            <Route path="/basic-addition-3" component={BasicAddition3} />

            <Route path="/basic-subtraction-1" component={BasicSubtraction1} />
            <Route path="/basic-subtraction-2" component={BasicSubtraction2} />
            <Route path="/basic-subtraction-3" component={BasicSubtraction3} />

            <Route path="/basic-multiplication-1" component={BasicMultiplication1} />
            <Route path="/basic-multiplication-2" component={BasicMultiplication2} />
            <Route path="/basic-multiplication-3" component={BasicMultiplication3} />

            <Route path="/basic-division-1" component={BasicDivision1} />
            <Route path="/basic-division-2" component={BasicDivision2} />
            <Route path="/basic-division-3" component={BasicDivision3} />

            <Route path="/medium-addition-1" component={MediumAddition1} />
            <Route path="/medium-addition-2" component={MediumAddition2} />
            <Route path="/medium-addition-3" component={MediumAddition3} />

            <Route path="/medium-subtraction-1" component={MediumSubtraction1} />
            <Route path="/medium-subtraction-2" component={MediumSubtraction2} />

            <Redirect to="/not-found" />
          </Switch>
        </div>
      </div>
    );
  }
}
