import combinate from 'combinate';
import _ from 'lodash';

const getPerCom = num => {
  return combinate({
    num: _.range(num, Number(`${num}1`), num),
    den: [num],
  });
};

const getLastNumber = number => {
  return _.chain(number).split('').last().toNumber().value();
};

export { getPerCom, getLastNumber };
