import React, { Component } from 'react';
import combinate from 'combinate';
import _ from 'lodash';

import TitleReload from '../../common/TitleReload';
import { getLastNumber } from '../../common/utils/combination';

export default class MediumSubtraction2 extends Component {
  state = {
    numbers: [],
  };

  componentDidMount() {
    this.handleReload();
  }

  getNumber = (number, index = 0) => {
    return _.toNumber(_.split(number, '')[index]);
  };

  handleReload = () => {
    const numbers = _.chain(
      combinate({
        num: _.range(10, 100),
        den: _.range(10, 100),
      }).filter(og => {
        const num = this.getNumber(og.num, 1);
        return og.num >= og.den && getLastNumber(og.num) < getLastNumber(og.den);
      }),
    )
      .shuffle()
      .take(50)
      .value();

    this.setState({ numbers });
  };

  render() {
    const { numbers } = this.state;
    return (
      <React.Fragment>
        <TitleReload title="Medium Subtraction #2" handleReload={this.handleReload} />
        <div className="flex flex-wrap gap-4 pt-5 text-lg md:text-2xl">
          {numbers.length &&
            numbers.map(number => {
              return (
                <div key={`${number.num}${number.den}`}>
                  <div className="flex flex-col">
                    <div className="px-5 bg-yellow-100 text-right">&nbsp;{`${number.num}`}</div>
                    <div className="px-5 bg-red-100">-{`${number.den}`}</div>
                  </div>
                </div>
              );
            })}
        </div>
      </React.Fragment>
    );
  }
}
