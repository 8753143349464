import React from 'react';
import { Link } from 'react-router-dom';

export default function Home() {
  return (
    <React.Fragment>
      <section id="basic">
        <h1 className="inline-block text-4xl border-b-2 mb-2">Basic</h1>
        <div className="flex flex-wrap gap-4">
          <div className="flex flex-col">
            <h2 className="text-3xl">Addition</h2>
            <Link to="/basic-addition-1" className="text-blue-700 hover:text-blue-800">
              Exercise 1
            </Link>
            <Link to="/basic-addition-2" className="text-blue-700 hover:text-blue-800">
              Exercise 2
            </Link>
            <Link to="/basic-addition-3" className="text-blue-700 hover:text-blue-800">
              Exercise 3
            </Link>
          </div>
          <div className="flex flex-col">
            <h1 className="text-3xl">Subtraction</h1>
            <Link to="/basic-subtraction-1" className="text-blue-700 hover:text-blue-800">
              Exercise 1
            </Link>
            <Link to="/basic-subtraction-2" className="text-blue-700 hover:text-blue-800">
              Exercise 2
            </Link>
            <Link to="/basic-subtraction-3" className="text-blue-700 hover:text-blue-800">
              Exercise 3
            </Link>
          </div>
          <div className="flex flex-col">
            <h1 className="text-3xl">Multiplication</h1>
            <Link to="/basic-multiplication-1" className="text-blue-700 hover:text-blue-800">
              Exercise 1
            </Link>
            <Link to="/basic-multiplication-2" className="text-blue-700 hover:text-blue-800">
              Exercise 2
            </Link>
            <Link to="/basic-multiplication-3" className="text-blue-700 hover:text-blue-800">
              Exercise 3
            </Link>
          </div>
          <div className="flex flex-col">
            <h1 className="text-3xl">Division</h1>
            <Link to="/basic-division-1" className="text-blue-700 hover:text-blue-800">
              Exercise 1
            </Link>
            <Link to="/basic-division-2" className="text-blue-700 hover:text-blue-800">
              Exercise 2
            </Link>
            <Link to="/basic-division-3" className="text-blue-700 hover:text-blue-800">
              Exercise 3
            </Link>
          </div>
        </div>
      </section>
      <section id="medium" className="pt-5">
        <h1 className="inline-block text-4xl border-b-2 mb-2">Medium</h1>
        <div className="flex flex-wrap gap-4">
          <div className="flex flex-col">
            <h2 className="text-3xl">Addition</h2>
            <Link to="/medium-addition-1" className="text-blue-700 hover:text-blue-800">
              Exercise 1
            </Link>
            <Link to="/medium-addition-2" className="text-blue-700 hover:text-blue-800">
              Exercise 2
            </Link>
            <Link to="/medium-addition-3" className="text-blue-700 hover:text-blue-800">
              Exercise 3
            </Link>
          </div>
          <div className="flex flex-col">
            <h1 className="text-3xl">Subtraction</h1>
            <Link to="/medium-subtraction-1" className="text-blue-700 hover:text-blue-800">
              Exercise 1
            </Link>
            <Link to="/medium-subtraction-2" className="text-blue-700 hover:text-blue-800">
              Exercise 2
            </Link>
          </div>
          {/*<div className="flex flex-col">
            <h1 className="text-3xl">Multiplication</h1>
            <Link to="/medium-multiplication-1" className="text-blue-700 hover:text-blue-800">
              Exercise 1
            </Link>
            <Link to="/medium-multiplication-2" className="text-blue-700 hover:text-blue-800">
              Exercise 2
            </Link>
            <Link to="/medium-multiplication-3" className="text-blue-700 hover:text-blue-800">
              Exercise 3
            </Link>
          </div>*/}
          {/*<div className="flex flex-col">
            <h1 className="text-3xl">Division</h1>
            <Link to="/medium-division-1" className="text-blue-700 hover:text-blue-800">
              Exercise 1
            </Link>
            <Link to="/medium-division-2" className="text-blue-700 hover:text-blue-800">
              Exercise 2
            </Link>
            <Link to="/medium-division-3" className="text-blue-700 hover:text-blue-800">
              Exercise 3
            </Link>
          </div>*/}
        </div>
      </section>
      {/*<section id="medium" className="pt-5">
          <h1 className="inline-block text-4xl border-b-2 mb-2">Advanced</h1>
          <div className="flex flex-wrap gap-4">
            <div className="flex flex-col">
              <h2 className="text-3xl">Addition</h2>
              <Link to="/advance-addition-1" className="text-blue-700 hover:text-blue-800">
                Exercise 1
              </Link>
              <Link to="/advance-addition-2" className="text-blue-700 hover:text-blue-800">
                Exercise 2
              </Link>
              <Link to="/advance-addition-3" className="text-blue-700 hover:text-blue-800">
                Exercise 3
              </Link>
            </div>
            <div className="flex flex-col">
              <h1 className="text-3xl">Subtraction</h1>
              <Link to="/advance-subtraction-1" className="text-blue-700 hover:text-blue-800">
                Exercise 1
              </Link>
              <Link to="/advance-subtraction-2" className="text-blue-700 hover:text-blue-800">
                Exercise 2
              </Link>
              <Link to="/advance-subtraction-3" className="text-blue-700 hover:text-blue-800">
                Exercise 3
              </Link>
            </div>
            <div className="flex flex-col">
              <h1 className="text-3xl">Multiplication</h1>
              <Link to="/advance-multiplication-1" className="text-blue-700 hover:text-blue-800">
                Exercise 1
              </Link>
              <Link to="/advance-multiplication-2" className="text-blue-700 hover:text-blue-800">
                Exercise 2
              </Link>
              <Link to="/advance-multiplication-3" className="text-blue-700 hover:text-blue-800">
                Exercise 3
              </Link>
            </div>
            <div className="flex flex-col">
              <h1 className="text-3xl">Division</h1>
              <Link to="/advance-division-1" className="text-blue-700 hover:text-blue-800">
                Exercise 1
              </Link>
              <Link to="/advance-division-2" className="text-blue-700 hover:text-blue-800">
                Exercise 2
              </Link>
              <Link to="/advance-division-3" className="text-blue-700 hover:text-blue-800">
                Exercise 3
              </Link>
            </div>
          </div>
        </section>*/}
    </React.Fragment>
  );
}
