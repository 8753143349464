import { Link } from 'react-router-dom';

export default function Navbar() {
  return (
    <header className="bg-gray-300">
      <div className="container md:mx-auto px-3 md:px-0">
        <div className="flex items-center gap-5">
          <div className="flex py-2 space-x-1">
            <img src="./icons/favicon-32x32.png" alt="Logo" />
            <h1 className="text-2xl">Kids Math</h1>
          </div>
          <div className="flex space-x-3">
            <Link className="nav-link bg-gray-200 rounded px-3 py-1 text-gray-600" to="/">
              Home
            </Link>
            <Link className="nav-link bg-gray-200 rounded px-3 py-1 text-gray-600" to="/table">
              Table
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}
